import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/macpro/Docs/projects/itzsrv-weblog-prod/node_modules/gatsby-theme-chronoblog/src/components/page/index.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AuthorBanner = makeShortcode("AuthorBanner");
const FeedSearch = makeShortcode("FeedSearch");
const Tags = makeShortcode("Tags");
const FeedItems = makeShortcode("FeedItems");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <AuthorBanner mdxType="AuthorBanner" />
    <p><strong parentName="p">{`Hey there`}</strong>{`, welcome to my blog. I’m a software engineer, based in India. I love learning and working on new technologies. These days I build webapps with JavaScript, a couple years ago it was Java.`}</p>
    <p>{`Here I post my notes, presentations, researches and experiences.
Basically, this is a web journal for my professional journey, and things that are important to document, finds it's space here.`}</p>
    <hr></hr>
    <FeedSearch mdxType="FeedSearch" />
    <Tags mdxType="Tags" />
    <FeedItems mdxType="FeedItems" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      